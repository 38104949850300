import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withTranslation } from 'gatsby-plugin-react-i18next'

import Analytics from 'src/utils/Analytics'
import SEO from 'src/components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReviewsSection from '../../components/ReviewsSection'
import { Anchor } from '../../components/ReviewsSection/styles'
import { LOCALIZATION, FRAME_PRODUCT_HANDLE, IS_FOREIGN_COUNTRY } from '../../utils/constants'

import FrameProductForm from '../../components/FrameProductForm'
import {
  ShopifyDescription,
  Thumbnail,
  ThumbnailRow,
  ThumbnailButton,
  Section,
  Container,
  ProductGridLeft,
  ProductGridRight,
  VideoContainer,
  Grid,
  ProductImage,
  GiftModeImage,
} from './styles'

const ANALYTICS_TITLE_OVERRIDES = {
  'skylight-tv': 'TV',
  'skylight-digital': 'TV',
}

const META_TITLE_OVERRIDES = {
  'skylight-tv': 'Skylight TV - Cast photos to your TV',
  'skylight-digital':
    'Skylight Digital - Turn any screen in your home into a digital photo frame you can email photos to.',
}

class FrameProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shownImageIndex: 0,
      showVideo: false,
      largerDevice: true,
      isPlusProduct: true,
      color: 'Black',
    }
    this.setShownImageIndex = this.setShownImageIndex.bind(this)
    this.analyticsTitle = this.analyticsTitle.bind(this)
    this.handleLargerDevice = this.handleLargerDevice.bind(this)
    this.handleColorSelection = this.handleColorSelection.bind(this)
    this.handlePlusProduct = this.handlePlusProduct.bind(this)
  }

  componentDidMount() {
    const {
      data: { product },
    } = this.props
    const { sku } = product.variants[0]
    setTimeout(() => {
      Analytics.track(`Viewed ${this.analyticsTitle()} Product Page`, { sku })
      const { largerDevice, color } = this.state
      if (!largerDevice && this.analyticsTitle() === 'Frame') {
        Analytics.track(`Viewed ${color} Frame Product Page`)
      }
    }, 3000)
  }

  handleLargerDevice(bool) {
    this.setState({ largerDevice: bool })
  }

  handleColorSelection(string) {
    this.setState({ color: string })
  }

  handlePlusProduct(bool) {
    this.setState({ isPlusProduct: bool })
  }

  setShownImageIndex(shownImageIndex) {
    this.setState({ shownImageIndex })
  }

  analyticsTitle() {
    const { data } = this.props
    return ANALYTICS_TITLE_OVERRIDES[data.product.handle] || 'Frame'
  }

  render() {
    const { shownImageIndex, showVideo, largerDevice, isPlusProduct, color } = this.state
    const { data, pageContext, t } = this.props
    const { product, badge } = data
    let { images } = product
    const { frame15, frameBlack, frameWhite, frameSilver, frameGold, framePoppy } = pageContext
    let productDescription = product
    if (!IS_FOREIGN_COUNTRY) {
      const productMap = {
        'frame.Black': frameBlack.data.shopifyProduct,
        'frame.Silver': frameSilver.data.shopifyProduct,
        'frame.White': frameWhite.data.shopifyProduct,
        'frame.Gold': frameGold.data.shopifyProduct,
        'frame.Poppy': framePoppy.data.shopifyProduct,
        'frame.largerDevice': frame15.data.shopifyProduct,
      }

      images = productMap[`frame.${color}`].images || images
      productDescription = productMap[`frame.${color}`]
    }
    if (largerDevice) {
      images = frame15.data.shopifyProduct.images
      productDescription = frame15.data.shopifyProduct
    }
    if (IS_FOREIGN_COUNTRY && !largerDevice && frameBlack) {
      images = frameBlack.data.shopifyProduct.images || images
      productDescription = frameBlack.data.shopifyProduct || productDescription
    }
    const shownImage = images[shownImageIndex]
    const is15inFrame = color === 'Black' && largerDevice
    const badgeData = badge.edges[0].node
    const badgeList = badgeData.show_pdp_badge

    const includes15inFrame = badgeList.includes('15" Black Frame')
    const hasColorBadge = badgeList.some((frame) => frame.includes(color))

    const displayBadge =
      (!IS_FOREIGN_COUNTRY && (is15inFrame ? includes15inFrame : hasColorBadge)) ||
      badgeList.includes(LOCALIZATION)

    return (
      <>
        <SEO
          title={t(META_TITLE_OVERRIDES[product.handle] || 'Skylight Frame - Digital Photo Frame')}
          description={product.description}
          imagePath={product.images[0].gatsbyImageData.images.fallback.src}
          path={`/products/${product.handle}/`}
        />
        <Section>
          <Container>
            <Grid>
              <ProductGridLeft>
                {showVideo === true ? (
                  <VideoContainer>
                    <iframe
                      title="Skylight Calendar Demo"
                      width="100%"
                      height="100%"
                      src="https://cdn.shopify.com/videos/c/o/v/4443230e58df472c841fa354b70242b7.mp4"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </VideoContainer>
                ) : (
                  <ProductImage
                    image={shownImage.gatsbyImageData}
                    key={shownImage.id}
                    alt={product.title}
                    className={largerDevice ? 'zoom' : 'standard'}
                  />
                )}
                {displayBadge && (
                  <GiftModeImage>
                    <GatsbyImage
                      image={
                        IS_FOREIGN_COUNTRY
                          ? badgeData.frame_badge.gatsbyImageData
                          : badgeData.international_badge.gatsbyImageData
                      }
                      alt="Frame Product Page Badge"
                    />
                  </GiftModeImage>
                )}
                <ThumbnailRow>
                  {images.map((image, index) => (
                    <ThumbnailButton
                      key={index}
                      type="button"
                      onClick={() => {
                        this.setShownImageIndex(index)
                        this.setState({ showVideo: false })
                      }}
                    >
                      <Thumbnail
                        image={image.gatsbyImageData}
                        key={image.id}
                        alt={`${product.title} Thumbnail ${index}`}
                      />
                    </ThumbnailButton>
                  ))}
                </ThumbnailRow>
              </ProductGridLeft>
              <ProductGridRight>
                <FrameProductForm
                  product={product}
                  frame15={frame15}
                  frameBlack={frameBlack}
                  frameWhite={frameWhite}
                  frameSilver={frameSilver}
                  frameGold={frameGold}
                  framePoppy={framePoppy}
                  handle={product.handle}
                  title={product.title}
                  simplifiedProductTitle={this.analyticsTitle()}
                  isLargerDevice={this.handleLargerDevice}
                  updateSelectedColor={this.handleColorSelection}
                  isPlusProduct={this.handlePlusProduct}
                  setShownImageIndex={this.setShownImageIndex}
                />
                <ShopifyDescription
                  dangerouslySetInnerHTML={{ __html: productDescription.descriptionHtml }}
                />
              </ProductGridRight>
            </Grid>
            <Anchor id="review" />
          </Container>
          <ReviewsSection productId="7073751498804" name="The Skylight Frame" />
        </Section>
      </>
    )
  }
}

FrameProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  t: PropTypes.func,
}

export const query = graphql`
  query ($handle: String!, $language: String!) {
    product: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      variants {
        id
        title
        price
        shopifyId
        sku
      }
      images {
        id
        gatsbyImageData
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "frame-product"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    badge: allContentfulFrameCalendarProductPageBadge {
      edges {
        node {
          frame_badge {
            gatsbyImageData(placeholder: NONE)
          }
          international_badge {
            gatsbyImageData(placeholder: NONE)
          }
          show_pdp_badge
        }
      }
    }
  }
`

export default withTranslation()(FrameProductPage)
